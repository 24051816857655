.full-height {
  height: 100%;
}

h1 {
  text-align: center;
}

.top-nav {
  display: flex;
  justify-content: center;
}

.top-nav-cell {
  padding-left: 50px;
  padding-right: 50px;
}

.top-nav-button {
  display: table-cell;
  vertical-align: middle;
}

.main:hover {
  cursor: pointer;
}

tr:hover {
  font-weight: bold;
}

/* @media (prefers-color-scheme: dark) {
  body,
  dialog {
    background-color: #1f2023 !important;
    color: #ebebebc0 !important;
  }

  th {
    color: #ebebebc0 !important;
  }

  tr {
    background-color: #1f2023 !important;
    color: #ebebebc0 !important;
  }

  tr:hover {
    background-color: #1f2023 !important;
    color: #e6e6e6 !important;
  }
} */
